import React, { FC } from 'react'

interface ILayoutProps {
    isDark?: boolean
    isNotCenteredVertically?: boolean
    className?: string
}

const Layout: FC<ILayoutProps> = ({
    children,
    isDark,
    isNotCenteredVertically,
    className,
}) => {
    return (
        <>
            <div
                className={`h-screen w-full ${className} ${
                    isDark && 'bg-black'
                }`}
            >
                <div
                    className={`flex flex-col ${
                        isNotCenteredVertically ? '' : 'justify-center'
                    } items-center h-screen border-gradient overflow-auto`}
                >
                    {children}
                </div>
            </div>
        </>
    )
}

export default Layout
