export const APPLE_URL =
    'https://apps.apple.com/us/app/bloom-анонимный-чат/id1595832954'

export const HELP_EMAIL = 'hello@bloomdates.com'
export const NAME_EN = 'Bloom'
export const NAME_RU = 'Bloom'

export const DEVELOPER_RU = ' Pyarun Advertising OU'
export const DEVELOPER_EN = ' Pyarun Advertising OU'

export const LAST_UPDATED = '26.11.2021'
