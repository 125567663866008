/* eslint-disable react/no-unescaped-entities */

import { Link } from 'gatsby'
import React from 'react'
import { Email } from '../../components/shared/email'
import LegalLayout from '../../components/shared/legal-layout'
import { DEVELOPER_EN, HELP_EMAIL } from '../../constants'
import useDomain from '../../hooks/use-domain'

const PolicyPage = () => {
    const domain = useDomain()
    return (
        <LegalLayout>
            <article>
                <h1>Privacy Policy</h1>
                The Privacy Policy ("Policy") applies to all information that
                {DEVELOPER_EN} and/or its affiliates ("Copyright Holder") may
                receive about the User ("User" or "You") during the use of any
                apps, programs, websites, products or services operated by the
                Copyright Holder ("Services" or "Services of the Copyright
                Holder") and during the execution of any agreements between the
                User and the Copyright Holder. The User's consent to the Policy,
                expressed by the User in the framework of a relationship with
                one of the listed persons, applies to all other listed persons.
                <br />
                <br />
                By using the Services of the Copyright Holder the User expresses
                unconditional consent to this Policy and the conditions for
                processing their personal information specified in it; in case
                of disagreement with these conditions, the User must refrain
                from using the Services.
                <ol>
                    <li>
                        <h2>
                            Users&#39; personal information processed by the
                            Copyright Holder
                        </h2>
                        <ol>
                            <li>
                                Within the framework of this Policy,
                                &quot;User&#39;s personal information&quot;
                                means:
                                <ol>
                                    <li>
                                        Personal information that User provides
                                        about themselves when signing up
                                        (creating an account) or when using the
                                        Services, including personal data of the
                                        User. In order to assess the Service the
                                        User must provide obligatory information
                                        that is marked in a special way. Other
                                        information is provided by the User at
                                        their discretion.
                                    </li>
                                    <li>
                                        Data that is automatically transmitted
                                        to the Copyright Holder&#39;s Services
                                        during their use through the software
                                        installed on the User&#39;s device,
                                        including IP address, cookie data,
                                        information about the User&#39;s browser
                                        (or other program that provides access
                                        to the Services), technical
                                        characteristics of the equipment and
                                        software used by the User, date and time
                                        of access to the Services, addresses of
                                        the requested pages, and other similar
                                        information.
                                    </li>
                                    <li>
                                        Other information about the User, the
                                        processing of which is required by the
                                        terms of use of certain Services of the
                                        Copyright Holder.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                This Policy applies only to information
                                processed during the use of the Services of the
                                Copyright Holder. The Copyright Holder does not
                                control and is not responsible for the
                                processing of information by third-party
                                services, which the User may access as a result
                                of a click on links available on the Services of
                                the Copyright Holder.
                            </li>
                            <li>
                                The Copyright Holder does not verify the
                                accuracy of the personal information provided by
                                the User and does not have the ability to assess
                                their legal capacity. However, the Copyright
                                Holder assumes that the User provides reliable
                                and sufficient personal information and keeps
                                the information up to date.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Purposes for processing Users&#39; personal
                            information
                        </h2>
                        <ol>
                            <li>
                                The Copyright Holder only collects and stores
                                the personal information that is required for
                                the provision of Services or the fulfillment of
                                agreements with the User, except for cases when
                                mandatory storage of personal information for a
                                certain period of time is required by law.
                            </li>
                            <li>
                                The Copyright Holder processes the User&#39;s
                                personal information for the following purposes:
                                <ol>
                                    <li>
                                        To identify the party within the
                                        Services and agreements with the
                                        Copyright Holder;
                                    </li>
                                    <li>
                                        To deliver personalized Services to the
                                        User and fulfill agreements;
                                    </li>
                                    <li>
                                        To communicate with the User, including
                                        notifications, requests, and information
                                        related to the Services, to fulfill
                                        agreements and process requests from the
                                        User;
                                    </li>
                                    <li>
                                        To improve the quality of Services,
                                        simplify them, and develop new Services;
                                    </li>
                                    <li>To target advertising materials;</li>
                                    <li>
                                        To conduct statistical and other
                                        researches that are based on
                                        depersonalized data.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Conditions for processing Users&#39; personal
                            information and its release to third parties
                        </h2>
                        <ol>
                            <li>
                                The Copyright Holder stores Users&#39; personal
                                information in accordance with the internal
                                regulations of certain services.
                            </li>
                            <li>
                                The User&#39;s personal information is kept
                                confidential, except for cases when the User
                                voluntarily provides information about
                                themselves for general access to an unlimited
                                number of persons. When using certain Services
                                the User agrees that a part of their personal
                                information becomes publicly available.
                            </li>
                            <li>
                                The Copyright Holder may share the User&#39;s
                                personal information with third parties in the
                                following cases:
                                <ol>
                                    <li>
                                        The User has agreed to such actions;
                                    </li>
                                    <li>
                                        The User needs to share such information
                                        in order to use a certain Service or to
                                        fulfill certain agreements between the
                                        User and the Service;
                                    </li>
                                    <li>
                                        The release of information is required
                                        by Belarusian or other applicable
                                        legislation within the framework of the
                                        procedure established by law;
                                    </li>
                                    <li>
                                        Such release of information occurs as
                                        part of a sale or other transfer of
                                        business (in whole or in part), while
                                        the acquirer is subject to all
                                        obligations to comply with the terms of
                                        this Policy in relation to the personal
                                        information received by them;
                                    </li>
                                    <li>
                                        In order to ensure possible protection
                                        of rights and legitimate interests of
                                        the Copyright Holder or third parties in
                                        cases where the User violates the{'  '}
                                        <Link
                                            to="legal/terms"
                                            title="Terms and Conditions agreement"
                                        />
                                        {'  '}
                                        the Services of the Copyright Holder,
                                        which is incorporated by reference into
                                        this Policy, this Policy, or documents
                                        containing the terms of use of certain
                                        Services.
                                    </li>
                                    <li>
                                        As a result of processing the User&#39;s
                                        personal information by depersonalizing
                                        it - depersonalized statistical data
                                        that has been obtained is transferred to
                                        a third party for conducting research,
                                        performing work or providing services on
                                        behalf of the Copyright Holder.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                When processing Users&#39; personal data, the
                                Copyright Holder is acting under the current
                                legislation of the Republic of Belarus.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Pixel tags and other similar technologies for data
                            collection
                        </h2>
                        <ol>
                            <li>
                                The Copyright Holder uses and may allow others
                                to use pixel tags and similar technologies (such
                                as web beacons and cookies) to recognize You and
                                your devices. The Copyright Holder uses pixels
                                to identify the User, remember their preferences
                                and settings, analyze traffic and trends,
                                implement and evaluate the effectiveness of
                                advertising campaigns, and support social
                                network functions.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Measures of User&#39;s personal information
                            protection
                        </h2>
                        <ol>
                            <li>
                                The Copyright Holder takes required and
                                sufficient organizational and technical measures
                                to protect the User&#39;s personal information
                                from unauthorized or accidental access,
                                destruction, modification, blocking, copying,
                                distribution, as well as from other illegal
                                actions committed by third parties.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Changes to this Privacy Policy. Applicable
                            legislation
                        </h2>
                        <ol>
                            <li>
                                The Copyright Holder has the right to modify
                                this Privacy Policy. The latest version of the
                                Policy shall come into effect upon publishing
                                unless otherwise provided by the new version of
                                the Policy. The current version is always
                                available at:{'  '}
                                <Link
                                    to="/legal/policy"
                                    title={`${domain}/legal/policy`}
                                />
                                .
                            </li>
                            <li>
                                The legislation of the Republic of Belarus
                                applies to this Policy and the relationship
                                between the User and the Copyright Holder
                                arising from the application of the Privacy
                                Policy
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Feedback. Questions and suggestions</h2>
                        <ol>
                            <li>
                                The User has the right to send any suggestions
                                or questions about this Policy to the user
                                support service at{'  '}
                                <Email email={HELP_EMAIL} />.
                            </li>
                        </ol>
                    </li>
                </ol>
            </article>
        </LegalLayout>
    )
}

export default PolicyPage
