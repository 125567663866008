import React, { FC } from 'react'
import Layout from './layout'

const LegalLayout: FC<{ logo?: string }> = ({ children }) => {
    return (
        <Layout isNotCenteredVertically>
            <div className="prose prose-sm max-h-full mt-3 mx-5 pb-11">
                {children}
                <div className="h-14" />
            </div>
        </Layout>
    )
}

export default LegalLayout
